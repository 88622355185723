import { useRoutes } from 'react-router-dom'

// routes
import AuthenticationRoutes from './AuthenticationRoutes'
import config from '../config'

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([AuthenticationRoutes], config.basename);
}
